import gql from 'graphql-tag'


const orderResponse = `
  id type name
  qty price totalPrice
  unitId unit {id name}
  stocks {
    id qty docId parentId orderId isPrinted
    inventoryId inventory {
      id code name
      unitId unit {id name}
    }
    createdAt createdBy createdUser {id name}
    approvedAt approvedBy approvedUser {id name}
  }
  ledgers {
    id docId stockId orderId amount
    accountId account {id code name}
    createdAt createdBy createdUser {id name}
    approvedAt approvedBy approvedUser {id name}
  }
`

export const LIST_ORDER = (templateType) => gql`query LIST_ORDER ($docType: String!, $docId: Int!) {
  orders: listDoc${templateType}Order (docType: $docType, docId: $docId) {${orderResponse}}
}`

export const LIST_ORDER_PAYABLE = (templateType) => gql`query LIST_ORDER_PAYABLE ($docType: String!, $q: FilterInput) {
  payables: listDoc${templateType}Payable (docType: $docType, q: $q) {
    id type code name remark dueAt
    orders {
      id total paid remaining
      account {
        id code name
      }
    }
  }
}`

export const LIST_ORDER_OTHER_CONFIG = (templateType) => gql`query LIST_ORDER_OTHER_CONFIG ($docType: String!, $contactId: Int!) {
  others: listDoc${templateType}OrderOtherConfig (docType: $docType, contactId: $contactId) {
    id type name isDefault withholdingPrice
  }
}`

export const CREATE_ORDER = (templateType) => gql`mutation CREATE_ORDER ($docType: String!, $docId: Int!, $input: DocSalePaymentOrderInput!) {
  createOrder: createDoc${templateType}Order (docType: $docType, docId: $docId, input: $input) {${orderResponse}}
}`

export const CREATE_ORDER_PAYABLE = (templateType) => gql`mutation CREATE_ORDER_PAYABLE ($docType: String!, $docId: Int!, $input: [DocSalePaymentOrderPayableInput]!) {
  createOrder: createDoc${templateType}OrderPayable (docType: $docType, docId: $docId, input: $input) {${orderResponse}}
}`

export const DESTROY_ORDER = (templateType) => gql`mutation DESTROY_ORDER ($docType: String!, $orderId: Int!) {
  destroyOrder: destroyDoc${templateType}Order (docType: $docType, orderId: $orderId) {id}
}`
